.sq-payment-form {
  color: #4a4a4a;
  font-family: 'Noah', sans-serif;
  width: auto;
}

.sq-fieldsetf {
  border-width: 0;
  margin-left: 0;
  margin-right: 0;
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 16px;
}

.sq-input {
  border: 1px solid #eceff3;
  display: flex;
  background: #ffffff;
  transition: border-color 200ms ease 0s, box-shadow 200ms ease 0s;
  border-radius: 4px;
  margin: 0;
  padding-bottom: 1px; /* fix bottom shadow */

  font-size: 14px;
  line-height: 1.5;
}
.sq-input:hover:not(.sq-input--focus):not(.sq-input--error) {
  border-color: #c6cedb; /* grey60 */
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
}

.sq-input--focus {
  border-color: #ea7200;
}

.sq-input--focus:hover {
  box-shadow: 0px 0px 2px #ea7200;
}

.sq-input--error {
  border-color: #e9554e; /* functionals.alert */
}

.sq-label {
  display: block;
  letter-spacing: 1.5px;
  line-height: 24px;
  font-size: 14px;
  font-weight: bold;
  margin-top: 24px;
}

.sq-creditcard {
  background: transparent;
  font-family: 'Rift Soft', sans-serif;
  height: 25px;
  padding: 0;
  color: #226937;
  font-size: 20px;
  margin: 0;
  outline: 0;
  box-shadow: none;
}

.sq-creditcard:hover {
  background-color: transparent;
}
